@import '../variables.sass'
@import '../mixins.sass'

.notification-wrapper
  position: absolute
  position: fixed
  top: 90px
  right: 30px
  z-index: 9999
  width: auto

  &__single
    width: 100%
    display: block
    margin: 10px 0 0 0
    background-color: rgba(white, .9)
    border: 1px solid $black01
    padding: 10px 0 10px 20px
    border-radius: 3px
    cursor: pointer
    opacity: 0
    position: relative
    @extend .clearfix
    animation: .5s ease 0s 1 normal forwards running show

    @keyframes show
      0%
        opacity: 0
        transform: translateY(-10px)

      100%
        opacity: 1
        transform: translateY(0px)

    @keyframes hide
      0%
        opacity: 1

      100%
        opacity: 0
        transform: translateY(10px)

    &__animation--autoclose
      animation: .5s ease 0s 1 normal forwards running show, .5s ease 5.5s 1 normal forwards running hide

    &--success
      background-color: rgba($green, .9)
      color: white
      .icon__fill
        fill: white
    &--notice
      background-color: rgba($orange, .9)
      color: white
      .icon__fill
        fill: white
    &--error,
    &--warning
      background-color: rgba($red, .9)
      color: white
      .icon__fill
        fill: white
    &--info
      background-color: rgba($blue, .9)
      color: white
      .icon__fill
        fill: white
      .icon-info
        .icon__fill
          fill: white
    &__close, &__cancel
      position: absolute
      top: 10px
      right: 5px
      width: unset
      border: none
      background-color: transparent
      -webkit-appearance: none
      cursor: pointer
      font-size: 16px
      padding: 2px 10px

      svg
        width: 16px
        height: 16px

    &__icon
      width: 20px
      height: 20px
      margin: 10px 20px 10px 0
      display: inline-block
      vertical-align: top
      float: left

      .icon
        width: 100%
        height: 100%

    &__title
      font-family: sans-serif
      font-weight: bold
      width: 100%
      display: block
      margin: 2.5px 0
      font-size: 16px
      padding: 0 15px 0 0

    &__message
      font-family: "Open Sans", sans-serif
      width: 100%
      max-width: 300px
      display: block
      margin: 2.5px 0
      padding: 0 15px 0 0
      line-height: 22px
      font-size: 14px

      p
        margin: 0

    &__box
      display: block
      display: inline-block
      vertical-align: top
      float: left
      padding: 5px 20px 5px 0

.notification-wrapper__single .notification-wrapper__single__close polygon
  fill: $gray2

.notification-wrapper__single--success,
.notification-wrapper__single--error,
.notification-wrapper__single--info,
.notification-wrapper__single--warning
   .notification-wrapper__single__close
      polygon
        fill: $white


.notification-wrapper-v2
  position: absolute
  position: fixed
  top: 90px
  left: 50%
  transform: translateX(-50%)
  z-index: 9999
  width: auto