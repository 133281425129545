@import '@root/variables.sass'

$short-field-width: 200px
$auth-form-width: 400px
$show-side-panel-width: $bp-1024

.auth
  display: flex
  justify-content: center
  min-height: 100vh

.form-login
  padding: 0 20px
  text-align: left
  width: $auth-form-width
  display: flex
  flex-direction: column
  justify-content: center
  @media (min-width: $show-side-panel-width)
      padding: 0 40px
      flex: 2.5

.form-login .auth-form-logo
  width: 220px
  margin: 0px 0 70px 0
  @media (min-width: $show-side-panel-width)
    display: none

.form-login__form
  max-width: $auth-form-width
  .form__input-wrapper--two_one_children
    margin-top: 35px

.form-login__title
  margin-top: 0
  margin-bottom: 20px
  position: relative
  line-height: 38px
  display: flex
  justify-content: space-between
  font-size: 28px
  color: $main-red
  font-weight: 600
  border-bottom: none

.form-login__link
  font-size: 16px
  color: $medium-blue2
  padding-top: 10px
  &:hover
    text-decoration: underline