@import '@root/variables.sass'
  
.action:hover
  cursor: pointer
  opacity: 0.8

.action--no-hover:hover
  cursor: default
  opacity: 1

.action--right
  position: absolute
  right: -40px
  top: -1px
  padding: 10px

.action--right:hover .icon .icon__fill
  fill: $medium-blue2

.action--inline
  display: inline-block

.action--icon-with-text .icon
  vertical-align: top
  margin-right: 5px
  margin-top: 1px

.action--disabled:hover
  cursor: not-allowed
