@import '../variables.sass'

.iconWrapper
  display: inline-block
  margin: 0 0 0 6px

  .icon 
    width: 16px
    height: 16px
    display: block
    margin: 0

  &--block
    display: block

.icon
  width: 16px
  height: 16px
  display: inline-block
  cursor: pointer

  &--centered
    display: flex
    justify-content: center
    align-items: center

  &--large
    width: 20px
    height: 20px

  &--extra-large
    width: 34px
    height: 34px

  &__fill
    fill: $gray2

  &-info
    .icon__fill
      fill: $main-blue-light

  &--block
    display: block

  &--img
    width:           $icon-size
    height:          $icon-size
    display:         inline-block
    vertical-align:  top
    margin:          0
  &--white .icon__fill
    fill: $white
  &--blue svg,
  &--blue .icon__fill
    fill: $main-blue

  &--gold .icon__fill
    fill: $gold

  &--gray .icon__fill
    fill: $gray3

  &--right
    position: absolute
    top: 0
    bottom: 0
    height: 100%
    display: flex
    justify-content: center
    flex-direction: column
    right: -20px

  &--inside
    right: 5px

  &--no-click
    pointer-events: none

  &.icon--disabled
    cursor: not-allowed

  &--rotate-180
    transform: rotate(180deg)
    margin-top: -3px !important

  &--rotate-90
    transform: rotate(-90deg)

  &--rotate-45
    transform: rotate(-45deg)

  &--size-125
    transform: scale(1.25)

  &--size-125-rotate-45
    transform: rotate(-45deg) scale(1.25)

.PlusIcon-blue
  fill: $blue

.PinIcon.active
  opacity: 1 !important
  cursor: pointer
  g
    fill: $orange !important
    opacity: 1

.PinIcon.needsUpdating
  g
    fill: $blue !important
    
a.Arrow > div.icon
  margin-top: -7px

.icon.star-icon
  .icon__fill.icon__fill--red
    fill: $red2
  .icon__fill.icon__fill--orange
    fill: $orange2
  .icon__fill.icon__fill--green
    fill: $green
  .icon__fill.icon__fill--gold
    fill: $gold
