@import '../../variables.sass'

.spinner
    margin: 100px auto
    width: 50px
    height: 40px
    text-align: center
    font-size: 10px
    min-width: 340px
    max-width: 100%

.spinner--small-margin
    margin: 30px auto

.spinner--inline
    width: 100%
    height: 20px
    display: inline-block
    vertical-align: top
    margin: 10px auto

.spinner--center
    position: absolute
    top: 45%
    left: 0
    right: 0

.spinner__modal-container
    position: absolute
    display: flex
    align-items: center
    top: 0
    left: 0
    right: 0
    bottom: 0
    height: 100%
    width: 100%
    background: rgba(255, 255, 255, 0.5)
    z-index: $z-index-popup

.spinner__rectangle
    background-color: #333
    height: 100%
    width: 6px
    display: inline-block
    margin-right: 2px
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out
    animation: sk-stretchdelay 1.2s infinite ease-in-out

.spinner .rect2
    -webkit-animation-delay: -1.1s
    animation-delay: -1.1s

.spinner .rect3
    -webkit-animation-delay: -1.0s
    animation-delay: -1.0s

.spinner .rect4
    -webkit-animation-delay: -0.9s
    animation-delay: -0.9s

.spinner .rect5
    -webkit-animation-delay: -0.8s
    animation-delay: -0.8s

@-webkit-keyframes sk-stretchdelay
    0%,
    40%,
    100%
    -webkit-transform: scaleY(0.6)

    20%
    -webkit-transform: scaleY(1.5)

@keyframes sk-stretchdelay
    0%,
    40%,
    100%
        transform: scaleY(0.6)
        -webkit-transform: scaleY(0.6)

    20%
        transform: scaleY(1.5)
        -webkit-transform: scaleY(1.5)
