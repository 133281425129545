.robot-wrapper
  position: fixed
  right: 0
  bottom: 0
  width: 70px
  height: 70px
  z-index: 2
  margin-right: 20px

  svg
    width: 50px
    height: 50px

.chat-list-page
  display: flex
  min-height: 653px

.chat-wrapper.fixed
  position: fixed
  right: 0
  bottom: 0
  z-index: 3
  overflow: hidden

  .back
    display: flex
    align-items: center
    padding: 14px
    border-bottom: 1px solid #aaa
    text-decoration: underline

    svg
      margin-right: 10px

  .room-row
    background-color: #fff

.epl-chat-wrapper
  display: flex

  .chat-unseen
    margin: -8px -9px

.welcome-avatar
  background-color: #5E7798

  svg
    width: 40px
    height: 40px
    
.chat-wrapper
  display: flex
  flex-direction: column
  max-width: 100vw
  width: 500px
  max-height: 100vh
  height: 650px
  background-color: #fff
  border: 1px solid #EBECF2
  border-top-left-radius: 7px
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15)
  font-family: "Open Sans", sans-serif
  font-size: 14px

  .back
    display: none
    
  .missing-permission
    padding: 7px
    display: flex
    justify-content: center
    border-top: 1px solid #eee

  textarea
    font-family: "Open Sans", sans-serif

  .chat-header
    color: #fff
    background-color: #3259A9
    width: 100%
    padding: 15px
    display: flex
    align-items: center
    justify-content: space-between
    line-height: 22px
    
    .chat-header-text
      margin-left: 5px

    .left
      display: flex
      align-items: center

      span
        margin-left: 15px

  .chat
    background-color: #fff
    flex: 1
    width: 100%
    padding: 0 20px 10px 20px
    overflow-y: scroll
    font-size: 14px

    .date
      display: flex
      justify-content: center

    > div:first-child
      padding-top: 10px

    .message
      position: relative
      border-radius: 12px
      background: #EBECF2
      padding: 7px 15px
      width: fit-content
      font-family: "Open Sans", sans-serif
      font-size: 14px
      white-space: pre-line
      word-break: break-word

      &:before 
        content: ""
        width: 0px
        height: 0px
        position: absolute
        border-left: 6px solid #fff
        border-right: 6px solid transparent
        border-top: 6px solid transparent
        border-bottom: 6px solid #fff
        background-color: #EBECF2
        left: -12px
        top: 0px

    .left 
      align-items: flex-start
      margin: -8px -8px 0 -8px

      .message
        margin-right: 100px

    .left.new
      .message
        border-top-left-radius: 0
        
    .time
      font-size: 12px
      padding: 3px 0
      
    .right
      flex-direction: row-reverse
      justify-content: end
      align-items: flex-start
      margin: -8px -8px 0 -8px
      
      .message-data
        justify-content: right

      .message
        color: #fff
        background: #F44236
        margin-left: 100px
      
        &:before 
          border-left: 6px solid transparent
          border-right: 6px solid #fff
          background-color: #F44236
          left: unset
          right: -12px

      .time
        text-align: right

    .right:not(.new) > div
      margin-right: 56px

    .left:not(.new) > div
      margin-left: 56px

    .right:not(.new) > div, .left:not(.new) > div
      padding-top: 4px

      .message:before
        border: none
    
    .right.new      
      .message
        border-top-left-radius: 12px
        border-top-right-radius: 0

    .message-data
      display: flex
      align-items: baseline

      .username
        font-family: sans-serif
        font-weight: bolder
        margin-right: 5px

  .picture
    background-color: transparent
  
  .default-profile
    width: 40px
    height: 40px
    color: #bbb

  .reply-text
    font-size: 11px

.chat-wrapper.list
  z-index: 2
  
.chat-room-wrap
  display: inline-block

.chat-list-wrapper
  max-width: 500px
  max-height: 585px
  overflow-y: scroll

  .no-messages
    padding-left: 10px

.correspondence-left .chat-list-wrapper
  width: 400px
  height: 650px
  overflow-y: scroll
  background: rgb(240, 240, 240)

.chat-icon-wrap
  display: flex
  float: right

.atheneum-contacts__name
  display: flex
  align-items: center

  .chat-icon-wrap
    margin-left: 10px

.room-row.active
  background: #fff

.room-row
  display: flex
  justify-content: space-between
  padding: 20px
  border-bottom: 1px solid #ddd
  border-top: 1px solid #ddd
  background: rgb(240, 240, 240)

  .room-name
    display: flex
    align-items: flex-start
    line-height: 22px
    margin-right: 20px

    .chat-unseen
      margin-left: 3px
  .time
    min-width: 60px

  .room-unseen
    font-family: "Roboto", sans-serif
    font-weight: bold
    width: 100%

  .room-seen
    width: 100%
    word-break: break-word

  .last-message
    display: flex
    margin-top: 5px
    font-weight: normal

    .picture
      margin-right: 5px
  
.chat-tabs
  background-color: rgb(240, 240, 240)
  margin-right: 2px
  padding: 10px 0 0

  .chat-tab
    font-weight: bold
    
  .MuiGrid-item
    margin-left: 30px
    padding: 0 0 10px 0

  .active.MuiGrid-item
    border-bottom: 3px solid #FF6D2A

.chat-unseen
  border-radius: 100px
  background: #CC291D
  color: #fff
  font-size: 10px
  display: flex
  justify-content: center
  align-items: center
  width: max-content
  min-width: 20px
  height: 20px
  margin-left: -15px

.robot-wrapper
  .chat-unseen
    position: absolute
    top: 0
    right: 0

.sections-one__section

  div:not(.picture)
    > .MuiSvgIcon-root
      color: rgb(50, 100, 221)

  .chat-wrapper
    z-index: 4

.spinners
  width: 50px
  height: 50px

.chat-footer
  background-color: #F5F5F5

  button
    background-color: #3B85BF
    float: right
    margin: 10px 25px 15px
    width: unset

@media (min-width: 1280px)

  .correspondence-wrapper 
    .correspondence-tabs
      margin-left: 45px
