@import '@root/variables.sass'

.password-strength-meter
    width: 100%

.password-strength-meter__bar
    border: 3px solid white
    width: 20%
    height: 9px

.password-strength-meter__bar--gray
    background-color: #E1E4EA

.password-strength-meter__bar--red
    background-color: #FF4F4F

.password-strength-meter__bar--yellow
    background-color: #FFE817

.password-strength-meter__bar--green
    background-color: #4ACC6C
