@import '../variables.sass'

.form__input-group
  min-height: 35px

.form__input-wrapper
  position: relative
  padding-bottom: 0
  font-size: 14px

.form__repeatable-input
  display: flex
  flex-direction: row
  position: relative
  width: 95%

.form__repeatable-input .form__input-wrapper
  width: 100%
  padding-left: 20px

.form__repeatable-input .form__input-wrapper:first-child
  padding-left: 0px

.form__repeatable-input .form__repeatable-action--add
  margin-left: 20px

.form__input-group + .form__input-group,
.form__repeatable-input + .form__repeatable-input
  margin-top: 20px

.form__input-group.form__input-group--no-flex
  display: block

.form__input-wrapper--two_one_children
  display: flex
  flex-wrap: wrap
  justify-content: space-between

.form__input-wrapper--two_one_children .form__input-wrapper:first-child
  flex: 2
  margin-right: 20px

.form__input-wrapper--two_one_children .form__input-wrapper:last-child
  flex: 1

.form__label
  font-size: 16px
  &.form__label--black
    color: $black

.form__input-error
  font-size: 12px
  color: $lighter-red
  margin-top: 4px

.form__input-wrapper--error input,
.form__input-wrapper--error button
  border: 1px solid $lighter-red
  background-color: $lighter-red2

// checkbox
.checkbox__wrapper
  min-height: 36px
  line-height: 36px
  display: inline-block

  .iconWrapper
      .icon
          margin: 2px 0 -2px 0

.checkbox
  margin-right: 8px

@media (min-width: 750px)
  .form__input-wrapper
    flex-basis: 470px
  .form__input-group
    display: flex
    flex-direction: row
    justify-content: space-between
