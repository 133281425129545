@import '@root/variables.sass'

.info-wrapper__row
  width: 100%
  font-size: 15px
  line-height: 1.3
  margin-bottom: 5px
  &--no-space
    display: flex
    .info-wrapper__row__name
      width: auto
      font-weight: bold
    .info-wrapper__row__value
      width: auto

.info-wrapper__row:last-child
  margin-bottom: 0

.info-wrapper__row__name
  color: $black
  font-weight: bold
  width: 45%
  display: inline-block
  vertical-align: top
  padding-right: 5px

.info-wrapper__row__value
  display: inline-block
  width: 53%
  vertical-align: bottom
  word-wrap: break-word
  .link
    word-break: break-all

.info-wrapper__list
  display: inline-block
  width: 55%
  margin: 0
  padding: 0
  word-wrap: break-word

.info-wrapper__row__list--bold
  display: inline-block
  width: calc(100% - 120px)
  font-weight: bold
  word-break: break-word

.info-wrapper__row__list .floating
  line-height: 31px

@media (min-width: 500px)
  .info-wrapper__row
    font-size: 16px
    line-height: 1.5
    