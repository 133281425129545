@import '@root/variables.sass'

.expert-profile__text
  font-size: 16px
  line-height: 24px
  word-break: normal
  overflow-x: auto

.expert-profile__text strong
  font-weight: bold

.expert-profile__text em
  font-style: italic

.expert-profile__text ol
  display: block
  list-style-type: decimal
  -webkit-margin-before: 1em
  -webkit-margin-after: 1em
  -webkit-margin-start: 0px
  -webkit-margin-end: 0px
  -webkit-padding-start: 40px

.expert-profile__text ul
  display: block
  list-style-type: disc
  -webkit-margin-before: 1em
  -webkit-margin-after: 1em
  -webkit-margin-start: 0px
  -webkit-margin-end: 0px
  -webkit-padding-start: 40px

.expert-profile__text li
  display: list-item
  list-style-position: inside

.expert-profile__text table td
  padding-left: 5px
  border: 1px solid black
  word-break: normal
  min-width: 100px

.expert-profile__text p + p
  margin-top: 12px

.sections-one__title--black
  color: #33343A
  margin-left: 15px

.language-label
  width: 160px
  color: #858485

@media (min-width: 768px)
  .close-section.epl__actions
    margin-top: 30px
    padding-top: 10px
    border-top: 1px solid rgba(182, 192, 205, 0.5)
  
  .form__section
    display: flex

.single-form-section
  margin-top: 30px
  padding-top: 10px

.error-message
  font-size: 12px
  color: $lighter-red
  margin-top: 4px

.requestor-section
  margin-top: 30px
  padding-top: 10px
  border-top: 1px solid rgba(182, 192, 205, 0.5)
  div
    margin-top: 15px

