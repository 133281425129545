.container
    .create-project
        max-width: 885px
        padding: 20px
        background-color: #fff

        ::-webkit-scrollbar 
            width: 5px
            height: 5px
        ::-webkit-scrollbar-track 
            background: transparent
        ::-webkit-scrollbar-thumb 
            background: #aaa
            border-radius: 100px
        ::-webkit-scrollbar-thumb:hover
            background: #555

        h2
         padding-left: 20px

        .MuiStepper-root
            width: 100%
        
        .MuiStepLabel-active
            font-weight: bolder
            color: #3f51b5

        .MuiStepIcon-root.MuiSvgIcon-root
            width: 1.25em
            height: 1.25em

        .MuiStep-vertical.MuiStep-alternativeLabel
            margin: 10px 0

        .essentials

            .MuiStepLabel-label
                padding-left: 30px
                margin: 0


            .MuiStepContent-root    
                padding-left: 50px
                padding-right: 60px


        .form-footer
            display: flex
            justify-content: space-between
            padding: 10px 44px

            .MuiButton-root
                width: fit-content
                align-self: flex-end

        #registerProjectForm
            padding: 10px 44px
            min-height: 500px

        .project-details
            padding: 10px 0
            border-bottom: 1px solid gray
            margin-bottom: 20px

        .industries
            > div:not(:first-child)
                margin-top: 20px

        .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child 
            padding: 7px 0 12px
        
        .MuiInputBase-input
            padding: 7px 0 12px
        
        .MuiAutocomplete-endAdornment
            display: flex

            button
                width: 50%

        .stepper-button
            padding: 12px 40px
            font-size: 14px

        .stepper-button.cancel
            margin-right: 5px

        .form-title
            font-size: 0.875rem
            color: #3f51b5
            font-weight: bolder
            padding-bottom: 5px
        
        .form-subtitle
            font-size: 1rem
            padding-bottom: 5px
            border-bottom: 1px solid gray

        .scopes
            margin: 30px 0 0
            padding-bottom: 30px
            display: inline-flex
            flex-wrap: nowrap
            overflow-x: scroll

            .scope
                border: 2px solid #01B98E
                border-radius: 5px
                min-width: 222px
                max-width: 222px
                margin-right: 30px

            .scope-field
                font-size: 14px

            .field-list
                min-height: 250px

            .field-name
                display: flex
                justify-content: space-between
                font-size: 0.875rem
                font-weight: bolder
                padding: 5px 0
                line-height: 20px

                .expert-count
                    font-weight: normal

            .field-value
                padding-bottom: 10px

            button
                width: 30px
                height: 20px

            .scope-name
                color: #01B98E
                
                .name
                    flex: 1
                
                svg
                    font-size: 20px

            .scope-footer
                padding-top: 10px
                border-top: 1px solid gray

            .add-scope
                display: flex
                justify-content: center
                align-items: center

                button
                    height: unset
                    width: unset
                    border-color: #01B98E
                    color: #01B98E
                    text-transform: unset
                    padding: 15px 10px

                .MuiSvgIcon-root
                    color: lightgray

        .question
            position: relative
            .index
                position: absolute
            
            > div:not(.index)
                padding-left: 30px    

            button
                position: absolute
                right: -15px
                top: 20px
                width: 25px

        .add-question
            display: flex
            font-size: 15px

        .screening-questions-wrapper
            max-height: 380px
            overflow-y: scroll
            padding: 30px 0 

        .scope-modal
            position: fixed
            top: 0
            left: 0
            bottom: 0
            width: 100%
            background: rgba(0,0,0,0.5)
            display: flex
            justify-content: center
            align-items: center
            z-index: 2

            .add-scope
                background-color: #fff
                max-width: 800px
                min-height: 636px
                padding: 30px

                .scope-name
                    margin-top: 15px

            .details
                min-height: 348px

    .form-tabs
        border-bottom: 1px solid gray
        display: flex
        margin-bottom: 10px

        .scope-tab
            font-size: 0.875rem
            color: gray
            font-weight: bolder
            padding-bottom: 5px
            margin: 5px 10px 0

        .scope-tab.active
            color: #3f51b5
            border-bottom: 2px solid #3f51b5
        
    .cancel-modal    
        position: fixed
        top: 0
        left: 0
        bottom: 0
        width: 100%
        background: rgba(0,0,0,0.5)
        display: flex
        justify-content: center
        align-items: center

        .cancel-wraper
            background-color: #fff
            max-width: 800px
            min-height: 340px
            padding: 50px
                
        .cancel-title
            font-weight: bold
            font-family: "Roboto", "Helvetica", "Arial", sans-serif
            font-size: 18px
            margin-bottom: 30px

        .link
            color: #3259A9
            margin-right: 5px
            cursor: pointer
            display: block
            padding: 20px 0
            text-decoration: underline
            font-size: 18px
            margin-top: -20px
            font-weight: bolder

        .helper-text
            font-style: italic
            font-size: 14px
            font-family: "Roboto", "Helvetica", "Arial", sans-serif
            color: #aaa
            margin-top: -10px
            margin-bottom: 20px

            p
                padding-bottom: 3px

        button
            width: auto
            float: right

@media (max-width: 1000px)

  .filter-tabs
    .sections-two
      display: none

  .container 

    .create-project 

        .MuiStepper-root
          padding: 20px 0

        #registerProjectForm
            padding: 20px 0

            .form-footer
                padding: 20px 0 20px

        .industries
            > div:last-child
                margin-top: 32px

        .scope-modal 
            z-index: 10
            overflow-y: scroll
            background-color: #fff

            .add-scope
                max-width: unset
                max-height: unset
                height: 100%

                .scope-name
                    margin-top: 10px

            .details
                min-height: unset

        .form-footer
            padding: 0

            button
                padding: 9px 20px
            > div
                display: flex

