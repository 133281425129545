@import '../variables.sass'
$expert-compliance-form-width: 400px
$show-side-panel-width: 1024px

.temp-responsive
    min-width: 100% !important      
    font-size: 14px
    font-family: 'Open Sans', sans-serif

.expert-compliance
    display: flex
    justify-content: center
    min-height: 100vh

.form-expert-compliance
  padding: 0 20px
  text-align: left
  width: $expert-compliance-form-width
  display: flex
  flex-direction: column
  justify-content: center
  @media (min-width: $show-side-panel-width)
    padding: 0 40px
    flex: 2.5

  .expert-form__input-group
    display: block

  .expert-form__label
    line-height: 14px

.form-expert-compliance .expert-compliance-form-logo
  width: 220px
  margin: 0px 0 70px 0
  @media (min-width: $show-side-panel-width)
    display: none

.form-expert-compliance__form
  max-width: $expert-compliance-form-width
  .form__input-wrapper--two_one_children
    margin-top: 35px

.form-expert-compliance__title
  margin-top: 0
  margin-bottom: 20px
  position: relative
  line-height: 38px
  display: flex
  justify-content: flex-start
  align-items: center
  font-size: 28px
  font-weight: 600
  border-bottom: none

.form-expert-compliance__subtitle
  color: $main-red
  font-size: 24px

.form-expert-compliance__link
  font-size: 16px
  padding-top: 10px
  &:hover
    text-decoration: underline

.form-inner
  border: 1px solid $medium-gray
  padding: 30px
  max-width: 700px

.expert-compliance-form-button-section
  display: flex
  justify-content: flex-end
  align-items: center
  margin-top: 15px
  a
    margin-right: 20px
    font-weight: normal!important

.expert-compliance-text
  line-height: 26px
  a
    font-weight: bold
    display: block
    color: $black
    text-decoration: underline
    cursor: pointer

.expert-compliance-icon
  margin-right: 15px

.unsubscribe-box
    .questions
        .item
            .question
                margin-bottom: 30px

            .answers
                display: flex
                flex-direction: column

                .radio-button-input:not(:last-child)
                    margin-bottom: 10px

                .radio-button-input
                    label
                        margin-bottom: 0

    .expert-form-login__title
        font-size: 23px
        font-weight: bold

.unsubscribe-box > *
    margin-bottom: 28px

.unsubscribe-button__wrapper
    position: relative
    background-color: #b6c0cd

    &.disabled
        .button
            background-color: transparent !important
            position: absolute
            top: 0
            right: 0
            bottom: 0
            left: 0
            border: 1px solid #b6c0cd !important

            &:hover
                border-color: #b6c0cd !important
                color: white !important

        .progress
            display: block
            background-color: transparent !important

    .button
        width: 100%

    .progress
        background-color: #8A2619 !important
        width: 0%
        height: 100%
        min-height: 35px
        display: none

.contact-types
    .basic-info
        .title
            font-size: 16px
            color: #020202
            font-weight: 600
            margin: 10px 0

    .item
        display: flex
        justify-content: space-between
        align-items: center
        padding-bottom: 18px
        border-bottom: 1px solid #dfdfdf
        margin-bottom: 18px

    .item:last-child
        border-color: #707070

    .toggle-contact-types
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 18px

        .title
            margin-right: 20px
            font-weight: 600
            font-size: 16px
            color: #020202

    .label
        margin-bottom: 20px

.reason-container
    .title
        font-weight: 600
        font-size: 16px
        margin-bottom: 12px
        color: #020202

    .select__wrapper
        width: 100%

.thankyou-header
    display: flex
    flex-direction: column
    align-items: center

    .title
        color: #020202
        font-size: 20px
        font-weight: bold
        margin-bottom: 18px

    .checkmark
        width: 50px
        height: 40px
        margin-bottom: 18px

        svg
            width: 100%
            height: 100%
            fill: #01A299

.subscribe-again
    margin-top: 75px
    border-top: 1px solid #707070
    display: flex
    padding-top: 20px

    span:not(:last-child)
        margin-right: 10px

    .text-link
        font-weight: bold
        text-decoration: underline
        color: #3A86BF
