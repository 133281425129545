.clearfix:after
  visibility: hidden
  display: block
  font-size: 0
  content: " "
  clear: both
  height: 0

.clearfix
  display: inline-block

* html .clearfix
  height: 1%

.clearfix
  display: block
