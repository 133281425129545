@import '../../variables.sass'

button
  border: none
  background-color: transparent
  -webkit-appearance: none
  cursor: pointer
  font-size: 14px
  @media (min-width: $desktop-screen-size)
    font-size: 16px

.button
  min-height: 35px
  min-width: 77px
  font-size: 14px
  line-height: 35px
  padding: 0 12px
  text-decoration: none
  display: inline-block

.button + .button
  margin-left: 20px

.button--primary
  color: white

.button--secondary
  color: $main-blue !important
  background-color: $white !important
  transition: color 0.2s ease-out

.button--transparent
  color: $main-blue-light

.button--primary
  border-width: 1px
  border-style: solid
  border-color: $main-blue !important
  border-radius: 3px
  background-color: $main-blue !important
  transition: border-color 0.2s ease-out, background-color 0.2s ease-out

.button--secondary
  border: 1px solid $main-blue !important
  border-radius: 3px
  transition: border-color 0.2s ease-out, background-color 0.2s ease-out

.button--primary:disabled,
.button--primary:disabled:hover
  opacity: 0.65
  border-color: $main-blue-light !important
  background-color: $main-blue-light !important

.button--secondary:disabled,
.button--secondary:disabled:hover
  opacity: 0.65
  border-color: $main-blue-light !important
  color: $main-blue-light !important
  background-color: white

.button--primary:hover,
.button--primary:active,
.button--secondary:hover,
.button--secondary:active
  border: 1px solid $main-blue-light !important
  border-radius: 3px
  background-color: $main-blue-light !important

// make sure icons change color properly after button changes it's
.button--primary:hover .icon--blue .icon__fill,
.button--primary:active .icon--blue .icon__fill,
.button--secondary:hover .icon--blue .icon__fill,
.button--secondary:active .icon--blue .icon__fill
  fill: $white

.button--secondary:hover,
.button--secondary:active
  color: white !important

.button--full
  display: block
  width: 100%

.button--small
  min-width: 35px
  width: 35px
  padding: 0

.button--thick
  font-weight: 600
  border-width: 2px !important
  letter-spacing: 0.03em

.button--white
  background-color: transparent
  border-color: $white
  color: $white

  &:hover
    background-color: $white
    border-color: $white
    color: $black

.button--orange
  border-color: transparent !important
  color: $orange !important

  &:hover
    background-color: $white !important
    border-color: transparent !important
    color: $orange !important

.button--red
  background-color: $main-red !important
  color: $white !important
  border-color: transparent !important

  &:hover
    background-color: $white !important
    color: $main-red !important
    border-color: $main-red !important

.button--red-white
  background-color: $white !important
  color: $main-red !important
  border-color: $main-red !important

  &:hover
    background-color: $main-red !important
    color: $white !important

.button--green-white
  background-color: $white !important
  color: $main-green !important
  border-color: $main-green !important
  &:hover
    background-color: $main-green !important
    color: $white !important
    border-color: $main-green !important

.button--orange-white
  background-color: $white !important
  color: $main-orange !important
  border-color: $main-orange !important
  &:hover
    background-color: $main-orange !important
    color: $white !important
    border-color: $main-orange !important

.button--blue-white
  background-color: $white !important
  color: $main-blue !important
  border-color: $main-blue !important

  &:hover
    background-color: $main-blue !important
    color: $white !important
    border-color: $main-blue !important
