@import '@root/variables.sass'

.Pagination
  text-align: center
  font-size: 18px
  font-weight: 600
  color: $dark-gray
  margin: 15px 0 20px 0

  .icon 
    width: 15px
    height: 15px
    display: block
    margin: auto !important

    svg 
      width: 100%
      height: 100%
      display: block

  div
    display: inline-block
    vertical-align: top
    cursor: pointer
    padding: 0 6px
    color: $dark-gray

    &.active
      color: $medium-blue2

    &.disabled
      color: $dark-gray
      cursor: default

    &.Arrow
      width: 30px
      height: 24px
      display: inline-flex
      align-items: center
      
      div
        vertical-align: middle

.icon--rotate-180
  transform: rotate(180deg)
  margin-top: -4px !important
