@import '../../variables.sass'
  
.button
  width: auto
  min-height: 35px
  min-width: 77px
  font-size: 14px
  line-height: 35px
  padding: 0 12px
  text-decoration: none
  display: inline-block

.button--primary
  border: 1px solid $medium-blue2 !important
  background-color: $medium-blue2 !important
  color: $white

.button--secondary
  color: $medium-blue2 !important
  background-color: $white !important
  border: 1px solid $medium-blue2 !important
  border-radius: 3px

.button--green
  background-color: $green
  color: $white
  border: 1px solid $green
  border-radius: 3px

.button--red
  background-color: $main-red-light
  color: $white
  border: 1px solid $main-red-light
  border-radius: 3px

.button--small
  min-width: auto

.button--small .icon__fill
  fill: $medium-blue2

.button--small:hover .icon__fill
  fill: $white

.button--primary:hover,
.button--primary:active,
.button--secondary:hover,
.button--secondary:active
  border: 1px solid $medium-blue2-light
  background-color: $medium-blue2-light
  color: $white

.button--primary:disabled,
.button--primary:disabled:hover
  opacity: 0.65
  cursor: not-allowed
