@import '@root/variables.sass'
  
html,
body
  min-width: 320px
  max-width: 100%
  min-height: 100%
  min-height: 100vh
  background-color: $white
  color: $black

/* apply a natural box layout model to all elements, but allowing components to change */
html
  box-sizing: border-box

*,
*:before,
*:after
  box-sizing: inherit

body
  margin: 0
  padding: 0
  font-family: 'Open Sans', sans-serif
  overflow-x: hidden

label
  color: $gray2
  font-size: 15px
  line-height: 30px

input, textarea
  border: 1px solid $gray
  border-radius: 3px
  background-color: $white
  color: $black
  font-size: 15px
  line-height: 1.3
  padding: 8px
  width: 100%

input[type="checkbox"]
  border-radius: 0
  position: relative
  top: 6px
  cursor: pointer
  width: auto
  -webkit-appearance: none
  &:focus
    outline: none
  &:checked:after
    font-size: 20px
    content: '\2713'
    position: absolute
    top: -4px
    left: 1px

a
  text-decoration: none

b
  font-weight: bold

h1
  font-size: 28px
  color: $black
  font-weight: 600
  line-height: 25px

h2
  font-size: 20px
  color: $black
  line-height: 24px
  font-weight: normal

h3
  font-size: 16px
  color: $black
  line-height: 24px
  font-weight: bold

button
  width: 100%
  border-radius: 3px
  cursor: pointer

hr
  background-color: rgba(182, 192, 205, 0.5)
  margin: 20px 0px
  height: 1px
  border: 0

.flex-items
  display: flex

.m-t-md
  margin-top: 10px !important

.m-t-lg
  margin-top: 20px !important

.m-t-xl
  margin-top: 30px !important

.m-r-md
  margin-right: 10px !important

.m-b-sm
  margin-bottom: 5px !important

.m-b-md
  margin-bottom: 10px !important

.m-b-lg
  margin-bottom: 20px !important

.m-l-md
  margin-left: 10px !important

//swal global class and overrides
.swal-global
  width: 580px !important
  padding: 35px 70px 55px 70px !important
  border-radius: 0 !important
  font-family: 'Open Sans', sans-serif

.swal-global .swal2-icon
  border-width: 3px

.swal-global .swal2-icon.swal2-warning
  color: $yellow
  border-color: $yellow

.swal-global .swal2-icon.swal2-info
  color: $lighter-blue2
  border-color: $lighter-blue2

.swal-global .swal2-icon.swal2-question
  color: $lighter-blue2
  border-color: $lighter-blue2

.swal-global .swal2-icon.swal2-error
  border-color: $lighter-red

.swal-global .swal2-icon.swal2-error [class^='swal2-x-mark-line']
  background-color: $lighter-red

.swal-global .swal2-icon.swal2-success [class^='swal2-success-line']
  background-color: $green

.swal-global .swal2-icon.swal2-success .swal2-success-ring
  border: 3px solid $green

.swal-global .swal2-title
  margin: 0 0 20px !important
  font-size: 24px !important
  color: $black !important
  line-height: 1.2 !important

.swal-global .swal2-content
  padding: 5px 0 !important
  font-size: 18px !important
  font-weight: inherit !important
  color: $black !important

.swal-global button.swal2-styled
  min-height: 35px !important
  min-width: 95px !important
  font-size: 16px !important
  line-height: 33px !important
  padding: 0 12px !important
  margin: 15px 8px 0 !important

@media (min-width: 500px)
  label
    line-height: 36px
    font-size: 16px
  hr
    margin: 30px 0
