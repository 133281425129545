.atheneum-contacts {
  /* nothing here */
  color: #33343a;
}

.atheneum-contacts__title {
  color: #8a2619;
  font-size: 24px;
  margin-bottom: 35px;
}

.atheneum-contacts__title--modal {
  font-weight: 600;
  margin-top: 10px;
}

.atheneum-contacts__subtitle {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 10px;
}

.atheneum-contacts__title--margins {
  margin: 20px 0;
}

.atheneum-contacts__title .svg-icon,
.atheneum-contacts__link .svg-icon {
  margin-right: 10px;
}

.atheneum-contacts__name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.atheneum-contacts__person {
  position: relative;
  padding-left: 75px;
  margin-bottom: 25px;
}

.atheneum-contacts__avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 50%;
  object-fit: cover;
}

.atheneum-contacts__person--modal {
  padding-left: 0;
  padding-top: 40px;
  margin-bottom: 40px;
}

.atheneum-contacts__person--modal .atheneum-contacts__avatar {
  position: static;
  margin: 0 auto;
  margin-bottom: 20px;
}

.atheneum-contacts__person--modal + .atheneum-contacts__person--modal {
  border-top: 1px solid rgba(182, 192, 205, 0.5);
}

.atheneum-contacts__item + .atheneum-contacts__item,
.atheneum-contacts__name + .atheneum-contacts__name,
.atheneum-contacts__name + .atheneum-contacts__items {
  margin-top: 10px;
}

.atheneum-contacts__person--modal .atheneum-contacts__item + .atheneum-contacts__item,
.atheneum-contacts__person--modal .atheneum-contacts__name + .atheneum-contacts__name,
.atheneum-contacts__person--modal .atheneum-contacts__name + .atheneum-contacts__items {
  margin-top: 20px;
}

.atheneum-contacts__link {
  color: #3259a9;
  text-decoration: none;
  cursor: pointer;
  display: block;
  line-height: 1.4;
}

.atheneum-contacts__link:hover {
  text-decoration: underline;
}

/* This should fix alignment for all SVG icons */
.svg-icon {
  display: inline-block;
  position: relative;
}

.atheneum-contacts__info {
  padding-left: 10px;
}

.svg-icon svg {
  vertical-align: middle;
}
