@import '@root/variables.sass'

.no-content
  padding: 35px 10px
  text-align: center
  color: $black

.no-content__title
  margin-top: 40px
  font-weight: 600
  font-size: 18px
  line-height: 1.3

.no-content__text
  margin-top: 25px
  font-size: 15px
  line-height: 1.5

@media (min-width: 500px)
  .no-content__title
    font-size: 20px
    line-height: 1.5

  .no-content__text
    margin-top: 35px
    font-size: 16px
    line-height: 1.5
