.modal__overlay
  position: fixed
  top: 0px
  left: 0px
  right: 0px
  bottom: 0px
  width: 100%
  height: 100%
  overflow-y: scroll
  background-color: rgba(32, 42, 65, 0.7)
  display: flex
  padding-top: 60px
  padding-bottom: 60px
  box-sizing: border-box
  z-index: 10

.modal__content
  border: 1px solid rgb(204, 204, 204)
  background: rgb(255, 255, 255)
  border-radius: 4px
  outline: none
  padding: 30px 20px
  text-align: center
  box-sizing: border-box
  position: relative
  margin: auto
  width: 100%
  max-width: 800px
  position: absolute
  left: 0
  right: 0
  margin-bottom: 60px
  /* animation related */
  -webkit-transform: scale(0.7)
  -moz-transform: scale(0.7)
  -ms-transform: scale(0.7)
  transform: scale(0.7)
  opacity: 0
  -webkit-transition: all 0.3s
  -moz-transition: all 0.3s
  transition: all 0.3s

/* IE specific fixes
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
  .modal__content
    position: absolute
    left: 0
    right: 0
    margin-bottom: 60px

.modal__title
  color: #33343a
  font-size: 28px
  font-weight: 600
  line-height: 39px
  margin-bottom: 10px

.modal__text
  color: #33343a
  font-size: 18px
  line-height: 27px
  text-align: center

.modal__buttons
  margin-top: 30px
  display: flex
  justify-content: center

.modal__content--after-open
  /* opening animation */
  -webkit-transform: scale(1)
  -moz-transform: scale(1)
  -ms-transform: scale(1)
  transform: scale(1)
  opacity: 1

.modal__content--before-close
  /* closing animation */
  -webkit-transform: scale(0.7)
  -moz-transform: scale(0.7)
  -ms-transform: scale(0.7)
  transform: scale(0.7)
  opacity: 0

.ReactModal__Body--open
  overflow-y: hidden
  height: 100%

.modal__content .close-icon
  position: absolute
  top: -50px
  right: -16px
  cursor: pointer
  padding: 15px
  z-index: 10
  display: block

@media (min-width: 1024px)
  .modal__content
    padding: 40px

.modal--dialog, .modal--spinner
  padding-top: 80px
  padding-bottom: 80px

.modal__content .svg-icon +
  .modal__title, .modal__text
    margin-top: 35px