@import '@root/variables.sass'
  
.autocomplete__container
  position: relative

.autocomplete__input
  background-color: white
  border-collapse: separate
  border-radius: 4px
  border-spacing: 0
  border: 1px solid #d7dce4
  color: #333
  cursor: text
  display: block
  font-family: sans-serif
  font-size: 14px
  height: 36px
  outline: none
  overflow: hidden
  padding: 0 35px 0 5px
  position: relative
  text-align: left
  overflow: hidden
  text-overflow: ellipsis

.autocomplete__input.autocomplete__input--tags input
    border: none !important
    background: transparent !important
    outline: none
    font-size: 14px

.autocomplete__input--dropdown
  cursor: pointer

.autocomplete__input--dropdown.autocomplete__input--placeholder
  color: #aaa

.autocomplete__input--error
  border: 1px solid #FF4F4F
  background-color: rgba(255, 79, 79, 0.1)

.autocomplete__input--disabled
  opacity: 0.8
  cursor: not-allowed

.autocomplete__arrow-icon 
  position: absolute
  right: 12px
  top: 14px

svg.icon-animate
  transition: transform 0.3s ease

svg.icon-rotate
  transform: rotate(180deg)

/* RESULTS */
.autocomplete__results-container
  max-height: 282px
  background-color: $white
  border-bottom-left-radius: 3px
  border-bottom-right-radius: 3px
  border: 1px solid $gray
  left: 0
  overflow-y: auto
  position: absolute
  right: 0
  top: 100%
  z-index: 5

.autocomplete__item
  padding: 8px
  cursor: pointer

.autocomplete__item--active
  background-color: $medium-blue2-light !important
  color: $white

.autocomplete__item--selected
  color: rgba(0, 0, 0, 0.3)
  cursor: not-allowed

input[disabled]
  cursor: not-allowed
  background-color: #F1F1F1

.autocomplete__subindustry-item
  padding-left: 10px