@import '@root/variables.sass'

.tabs__container
  background-color: $white
  border-bottom: 1px solid rgba(182, 192, 205, 0.5)
  height: 50px
  overflow: hidden

.tabs__scrollable-container
  overflow: auto
  -webkit-overflow-scrolling: touch
  padding-bottom: 20px

.tabs
  margin: 0
  list-style: none

.tab
  font-size: 16px
  color: $black
  line-height: 22px
  display: inline-block
  text-transform: uppercase
  cursor: pointer
  padding-right: 5px
  transition: color ease-out 0.2s

.tab:hover .tab__link
  color: $main-red

.tab:focus .tab__link
  color: $main-red
  border-bottom: 3px solid $main-red

.tab + .tab
  padding-left: 5px
  margin-left: 15px

.tab--active
  font-weight: 700

.tab__link
  text-decoration: none
  color: $black
  padding: 15px 5px 9px
  display: inline-block

.tab--active .tab__link
  color: $main-red
  border-bottom: 3px solid $tab-border

@media (min-width: 500px)
  .tabs
    padding: 0 20px
  .tab + .tab
    margin-left: 30px

@media (min-width: 1130px)
  .tabs
    padding: 0 20px 0 0
  