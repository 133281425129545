@import '@root/variables.sass'
  
.sub-header
  background-color: $white
  padding: 20px 0
  border-bottom: 1px solid rgba(182, 192, 205, 0.5)

.sub-header__section + .sub-header__section
  margin-top: 10px

.sub-header__account
  color: $gray2
  font-size: 14px
  line-height: 25px

.sub-header__title
  font-weight: 600
  font-size: 26px
  line-height: 1.25
  color: $main-red

.sub-header__statistic
  color: $black
  display: flex
  justify-content: space-between

.sub-header__statistic-item
  text-transform: uppercase
  display: inline-block
  font-size: 11px
  line-height: 1.1
  display: flex
  flex-direction: row

.sub-header__statistic-item:last-child
  flex: none

.sub-header__statistic-number
  margin-right: 2px
  margin-top: -1px
  font-size: 26px
  line-height: 0.95

@media (min-width: 380px)
  .sub-header__statistic-item
    font-size: 12px
  .sub-header__statistic-number
    margin-right: 4px

@media (min-width: 800px)
  .sub-header__statistic-number
    margin-right: 6px
    font-size: 40px
    line-height: 0.95
  
  .sub-header__statistic-item
    font-size: 14px
    line-height: 1.4

  .sub-header__statistic-item
    flex: none

  .sub-header__statistic-item + .sub-header__statistic-item
    margin-left: 36px
    

