@import '@root/variables.sass'
  
.form__contact-information
  text-align: left
  max-width: 100%
  line-height: 1.3
  position: relative
  z-index: 1

.form__contact-information__title
  color: $main-red
  font-size: 26px
  font-weight: 600
  line-height: 1.3

.form__section .button--primary, .button--green
  margin-right: 20px

.separator--thick
  height: 3px

@media (min-width: 500px)
  .form__contact-information__title
    font-size: 28px

@media (max-width: 768px)
  .form__contact-information .form__section .button--primary
    margin-bottom: 20px
