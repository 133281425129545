@import '@root/variables.sass'
@import '@root/mixins.sass'

.info-box-wrapper
  width: 100%
  padding: 15px
  border-radius: 3px
  @extend .clearfix

  &__rows
    width: calc(100% - 30px)
    display: inline-block
    float: left
    font-size: 14px
    line-height: 1.5

    .single
      width: 100%
      margin: 0 0 10px 0

      &:last-child
        margin: 0

      span
        font-weight: bold

      p
        margin: 0

  &__icon
    width: 20px
    margin: 0 10px 0 0
    display: inline-block
    vertical-align: top
    float: left

    .icon
      margin: 0 !important

  &--blue
    border: 1px solid $lighter-blue2
    background-color: $lighter-blue

  &--red
    background-color: $lighter-red2
    border: 1px solid $lighter-red
    color: $lighter-red

    .icon__fill
      fill: $lighter-red  !important
      opacity: 1

  &--yellow
    background-color: $yellow-light
    border: 1px solid $yellow

    .icon__fill
      fill: $yellow !important
      opacity: 1
