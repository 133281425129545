@import '@root/variables.sass'

.epl__action
  padding-top: 15px

.filter__group
  display: flex
  justify-content: space-between
  
.sort__group
  display: flex
  align-items: center
  margin-bottom: 15px

.sections-two__link
  color: $medium-blue2
  margin-right: 5px
  cursor: pointer
  display: block
  padding: 0 0 20px 0
  text-decoration: underline
  font-size: 18px
  font-weight: bolder

@media (max-width: 768px)
  .epl__action
    margin-top: 15px
    border-top: 1px solid rgba(182, 192, 205, 0.5)

  .epl__action button,
  .form__section button
    width: 100%
    min-height: 44px

@media (min-width: 768px)
  .epl__actions
    display: flex
    justify-content: space-between
    flex-direction: row
    flex-wrap: wrap
    margin-top: 10px
    border-top: 1px solid rgba(182, 192, 205, 0.5)

@media (min-width: 1024px)
  .sections-one__link.epl__link
    position: absolute
    right: 10px
    top: 80px

.error-message
  font-size: 12px
  color: $lighter-red
  margin-top: 4px

.requestor-section
  margin-top: 30px
  padding-bottom: 15px
  padding-top: 15px
  border-top: 1px solid rgba(182, 192, 205, 0.5)
