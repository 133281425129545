.clearfix:after
  visibility: hidden
  display: block
  font-size: 0
  content: " "
  clear: both
  height: 0

.clearfix
  display: inline-block

* html .clearfix
  height: 1%

.clearfix
  display: block

.hide-text-overflow
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 1
  -webkit-box-orient: vertical

@mixin logo-dimension
  width: 192px
  height: 33px

@mixin login-title
  font-family: "Open Sans"
  margin-top: 0
  margin-bottom: 20px
  position: relative
  line-height: 38px
  display: flex
  justify-content: space-between
  font-size: 28px
  color: $main-red
  font-weight: 600
  border-bottom: none