$gray:               #D7DCE4
$gray2:              #858485
$light-gray:         #F1F1F8
$dark-gray:          #7E8DA2

$black:              #33343A
$black01:            rgba(0, 0, 0, 0.1)
$black04:            rgba(0, 0, 0, 0.4)
$white:              #FFFFFF
$dirty-white:        #FAFAFC

$main-red:           #8A2619
$main-red-light:     #B23120
$lighter-red:        #FF4F4F
$lighter-red2:       rgb(255,232,232)

$green:              #39AC57

$medium-blue2:       #3259A9
$medium-blue2-light: #3C6BCC
$lighter-blue:       #DDEDF9
$lighter-blue2:      #99C8EC

$yellow:             rgb(255, 176, 73)
$yellow-light:       #FEF8F0

$orange:             #FF6D2A

$tab-border:         #8A2518

$z-index-dropdown: 5
$z-index-popup: 10

$bp-1024: 1024px