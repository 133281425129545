@import '@root/variables.sass'
  
.view-container
  padding: 35px 0px
  background-color: $light-gray
  min-height: calc(100vh - 220px)

.filter-tabs.container
  margin-top: -30px

.sections-one
  flex: 1 1 auto

.sections-one__title
  color: $main-red
  font-size: 22px
  line-height: 1.4
  display: flex
  justify-content: space-between
  margin-bottom: 15px

.sections-one__action
  position: relative
  background: $dark-gray
  width: 20px
  border-radius: 2px
  height: 20px
  top: 6px
  cursor: pointer
  svg
    left: 4px
    bottom: 6px
    position: relative

.sections-one__section
  background-color: $white
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15)
  padding: 14px 14px
  position: relative
  line-height: 28px
  font-size: 16px

.sections-one__section + .sections-one__section
  margin-top: 11px

.sections-one__link-container
  font-size: 17px
  line-height: 1.3
  margin-bottom: 10px

  .icons
    display: flex
    float: right
    align-items: center

.sections-one__link
  color: $medium-blue2
  margin-right: 5px
  cursor: pointer
  &.contract
    line-height: 32px
    position: relative
    float: right
    bottom: 40px
    right: 10px
    .download--icon svg
      bottom: 3px

.status-indicator
  margin-right: 10px
  padding: 2px 15px
  border-radius: 15px
  font-size: 16px

  &.open 
    border: 2px solid #29B4F7
    background-color: #EDFBF7
    color: #4268B0
  &.complete 
    border: 2px solid #6CF961
    background-color: #EDFBF7
    color: #228970
  &.draft 
    border: 2px solid #E09AFC
    background-color: #FEEBFE
    color: #647DBE
  &.pending
    border: 2px solid #F7D671
    background-color: #FEFEEB
    color: #F7D871

.edit-link
  width: 100%
  button
    float: right
    width: 30px

.delete-modal    
    position: fixed
    top: 0
    left: 0
    bottom: 0
    width: 100%
    background: rgba(0,0,0,0.5)
    display: flex
    justify-content: center
    align-items: center
    border-radius: 5px
    z-index: 5

    .delete-wraper
        background-color: #fff
        max-width: 400px
        padding: 30px
        border-radius: 5px
        margin: 10px
            
    .project-name
        font-weight: bold
        font-family: "Roboto", "Helvetica", "Arial", sans-serif
        font-size: 17px
        margin-bottom: 30px
        line-height: 22px

    .buttons
        display: flex
        justify-content: space-between
        margin-top: 40px

svg.delete-icon
  margin-top: 3px
  cursor: pointer
  fill: #777

.sections-one__link.name
  display: flex
  justify-content: space-between

.project-actions
  display: flex
  justify-content: space-between
  align-items: flex-start
  
.sort-filter-tabs
  display: flex
  justify-content: flex-end
  margin-bottom: 10px

  .MuiFormControl-root
    min-width: 110px
    margin-left: 20px

  .tab-wrap
    position: relative
    width: 140px
    cursor: pointer

    .tab-options
      position: absolute
      top: 30px
      left: 0
      width: 100%
      background-color: #fff
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15)
      z-index: 2
      padding: 5px

      .option
        padding: 10px

    &:first-child
      width: 210px

  .filter-tab
    display: flex
    margin-right: 20px

  .tab-label
    display: flex

    span
      margin-left: 10px
      text-transform: capitalize


  button
    width: initial
    height: 20px


.sections-one__link:hover
  text-decoration: underline

.sections-two
  width: 320px
  padding: 35px 0

.sections-two__subtitle
  font-size: 16px
  font-weight: 600
  line-height: 1.5

.sections-two__link
  color: $medium-blue2
  margin-right: 5px
  cursor: pointer
  display: block
  padding: 0 0 20px 0
  text-decoration: underline
  font-size: 18px
  font-weight: bolder

.sections-one__link-container.Completed
  pointer-events: none

  .sections-one__link
    color: gray

@media (min-width: 500px)
  .view-container
    padding: 45px 0px
  .sections-one__title
    font-size: 24px
    line-height: 1.325
  .sections-one__link-container
    line-height: 1.5
    font-size: 18px

  .sections-one
    padding: 0 20px 0 0
    &--standalone
      padding-right: 340px
  .sections-two
    padding: 0px

@media (max-width: 1000px)
  .status-indicator 
    margin-top: -27px
    padding: 0px 10px
    font-size: 13px

  .sort-filter-tabs
    margin: 10px 0

  .sections-one
    padding: 0

  .sections-two
    width: unset