@import '../../variables.sass'

.select__wrapper
    position: relative
    z-index: 0
    border: 1px solid #d7dce4
    border-radius: 3px
    background-color: #fafafc
    display: inline-block
    margin: 0
    overflow: hidden

.select__wrapper--blue
    border-color: rgba(60, 107, 204, 0.8)
    color: rgba(60, 107, 204, 0.8)
    background-color: white

.select__wrapper--disabled
    opacity: 0.5

.select__wrapper--blue .select
    color: rgba(60, 107, 204, 0.8)
    font-size: 16px

.select__wrapper .icon--img
    position: absolute
    right: 7px
    left: auto
    bottom: 0
    display: block
    z-index: $z-index-base
    top: 10px
    margin-top: -12px
    display: inline-block
    height: 20px

.select__wrapper,
.select
    min-height: 35px
    min-width: 100px
    line-height: 35px

.select
    position: relative
    z-index: 1
    border: none
    background: transparent
    color: #33343a
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    padding: 0 20px 0 10px
    width: 110%
    font-size: 14px

.select:focus
    outline: none

.select::-ms-expand
    display: none // hide the default arrow in IE10 and IE11

label + .select__wrapper
    margin-left: 8px
