.checkbox__wrapper
    min-height: 36px
    line-height: 36px
    display: inline-block
    cursor: pointer

.checkbox__wrapper--disabled
    opacity: 0.85
    cursor: not-allowed

    input[type="checkbox"]
        cursor: not-allowed

.checkbox
    vertical-align: middle
    margin-right: 8px
    &__no-left-margin
      margin-left: 0
