@import '../../variables.sass'
@import '../../mixins.sass'

.auth-side-panel
    flex: 1.5
    height: 100vh
    background-color: $light-gray
    padding: 60px
    display: none
    @media (min-width: $bp-1024)
        display: block

.auth-side-panel .tree-icon
    display: block
    position: relative
    margin: 20px

.auth-side-panel .tree-icon svg
    width: 100%
    height: 425px

.auth-side-panel .logo
  @include logo-dimension

  svg
    width: 100%
    height: 100%
