.app
  min-height: 100vh
  display: flex
  flex-direction: column
  min-width: 320px

.container
  max-width: 1130px
  display: block
  justify-content: space-between
  margin: 0 auto
  padding-left: 10px
  padding-right: 10px

.icon
  width: 20px
  height: 20px

.chevron-icon
  position: relative
  top: -2px

@media (min-width: 600px)
  .container
    padding-left: 20px
    padding-right: 20px

@media (min-width: 1000px)
  .container
    display: flex
