@import '@root/variables.sass'
  
.header
  padding: 20px 0
  box-sizing: border-box
  border-bottom: 1px solid rgba(182, 192, 205, 0.5)
  background-color: $white

.header .container
  display: flex

.logo
  width: 159px
  height: 27px

.logo g
  fill: $main-red

.header__logout
  cursor: pointer
  transition: border .3s ease
  svg
    position: relative
    top: 2px
    width: 28px
    height: 28px
  path
    fill: $dark-gray
