@import '../../variables.sass'

.tooltipWrapper
  animation: .3s ease 0s 1 normal forwards running slideFadeIn
  @keyframes slideFadeIn
    0%
      opacity: 0

    100%
      opacity: 1

.wideTooltipWrapper
  animation: .3s ease 0s 1 normal forwards running slideFadeIn
  @keyframes slideFadeIn
    0%
      opacity: 0

    100%
      opacity: 1
  .rc-tooltip-inner
    max-width: 600px

.rc-tooltip-inner
  background-color: $dark-blue !important
  border-radius: 0
  font-size: 12px
  line-height: 17px
  padding: 10px 12px
  max-width: 350px

  .employee-initials-img
    width: 40px
    height: 40px
    margin: 5px 15px 5px 5px

  em, b, strong
    color: $orange

  p
    margin: 0

.rc-tooltip-placement-top .rc-tooltip-arrow
  border-top-color: $main-blue
  border-width: 10px 10px 0
  bottom: 0
  left: calc(50% - 5px)

.profile-wrapper
  display: block
  width: 100%

  &__avatar
    width: 30px
    height: 30px
    border-radius: 50%
    display: inline-block
    vertical-align: top
    object-fit: cover

.tooltipWrapper, .wideTooltipWrapper
  &.lightTooltipWrapper
    .rc-tooltip-inner
      color: $light-blue
      background-color: $white !important

    .rc-tooltip-arrow
      border-top-color: $white !important
